import React, { useState, useEffect, useRef } from "react";
import {  Button } from "../../Component";


export const SearchBar = ({ filter, setFilter,searchList,redirection }) => {    //added the search bar component
    const [isListVisible, setIsListVisible] = useState(false);
    const [navPuja, setNavPuja] = useState([]);
    const dropdownRef = useRef(null);
  
    const handleInputClick = () => {
      setIsListVisible(true);
    };
  
    const handleClickOutside = (event) => {    //function to handle click outside the search bar
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsListVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const handleInputChange = (e) => {
      setFilter(e.target.value);
      setNavPuja([]);
    };
  
    return (
      <div 
        className="container tw-flex tw-justify-between tw-gap-3.5 tw-self-stretch tw-border-[0.5px] tw-border-solid tw-border-blue_gray-100_01 tw-bg-white-a700 tw-p-10 tw-shadow-xs md:tw-flex-col sm:tw-bg-transparent sm:tw-border-none sm:tw-p-0 sm:tw-shadow-none"
      >
        <div className="tw-relative tw-flex-grow" ref={dropdownRef}>
          <input
            name="Search Input"
            placeholder="Search puja or event for details"
            value={filter}
            onChange={handleInputChange}
            onClick={handleInputClick}
            className="tw-flex-grow tw-border tw-w-[100%] tw-border-gray-500 tw-px-2.5 tw-font-quicksand tw-font-medium tw-text-gray-900 md:tw-px-[1.5] sm:tw-px-2.5" //adjusted the stylings
          />
          {isListVisible && searchList.length > 0 && (
            <div className="tw-absolute tw-z-10 tw-w-full tw-bg-[white] tw-mt-1 tw-bg-white tw-border tw-border-gray-200 tw-rounded-md tw-shadow-lg">
              <ul className="tw-max-h-60 tw-overflow-auto tw-font-[quicksand]">
                {searchList.map((puja, index) => (
                  <li
                    key={index}
                    onClick={() => {
                      setFilter(puja.name);
                      setIsListVisible(false);
                      setNavPuja(puja);
                    }}
                    className="tw-px-4 tw-py-2 tw-cursor-pointer hover:tw-bg-gray-50 tw-font-quicksand hover:tw-bg-[#f1e3f6]"
                  >
                    {puja.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <Button
          variant="fill"
          shape="square"
          className="tw-min-w-[128px] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] md:tw-px-5"
          onClick={() => redirection(navPuja, "none")}
        >
          Search
        </Button>
      </div>
    );
  };