import { Title, RatingBar } from "..";
import React from "react";
import { DEFAULT_USER_PROFILE } from "../../Constants/myDharmaContent";

export default function UserProfile({
  userName = DEFAULT_USER_PROFILE.userName,
  userDescription = DEFAULT_USER_PROFILE.userDescription,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} tw-flex tw-flex-col tw-items-center tw-w-[32%] md:tw-w-full tw-gap-5 tw-p-[30px] sm:tw-p-5 tw-border-blue_gray-100_01 tw-border-[0.5px] tw-border-solid tw-bg-white-a700 tw-shadow-xs`}
    >
      <div className="tw-flex tw-flex-col tw-items-start tw-gap-1 tw-self-stretch">
        <Title size="textlg" as="p" className="tw-text-[24px] tw-font-medium tw-text-primary_maroon">
          {userName}
        </Title>
        <RatingBar value={5} isEditable={true} size={16} className="tw-flex tw-gap-2.5" />
      </div>
      <Title
        size="texts"
        as="p"
        className="tw-mb-10 tw-w-full tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-line-clamp-3" // Clamp the text to 3 lines
      >
        {userDescription}
      </Title>
    </div>
  );
}
