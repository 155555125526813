import { Title, Img } from "..";
import React from "react";

export default function ImportantDatesTracker({
  importantDatesTitle = "Track important dates",
  importantDatesDescription = "Track all your important family dates, birthdays, ancestral dates. The app will remind you and help you perform the required action",
  imgSrc,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} tw-flex sm:tw-flex-col tw-items-center tw-w-full tw-gap-2.5 tw-px-5 tw-py-8 sm:tw-py-5 tw-border-blue_gray-100_01 tw-border-[0.5px] tw-border-solid tw-bg-white-a700 tw-shadow-xs sm:tw-shadow-none sm:tw-h-[312px]`}
    >
      <Img src={imgSrc} alt="App Icon" className="h-[124px] tw-w-[124px] tw-object-cover" />
      <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start sm:tw-items-center">  {/* adjusted the stylings */}
        <Title size="textlg" as="p" className="tw-text-[24px] tw-font-normal tw-text-primary_maroon">
          {importantDatesTitle}
        </Title>
        <Title
          size="texts"
          as="p"
          className="tw-w-[94%] tw-text-[14px] tw-font-medium tw-leading-6 tw-tracking-[0.14px] tw-text-gray-900 sm:tw-w-[100%] sm:tw-text-center"
        >{/* adjusted the stylings */}
          {importantDatesDescription}
        </Title>
      </div>
    </div>
  );
}
