import React from "react";

const sizes = {
  text2xl: "tw-text-[24px] tw-font-normal md:tw-text-[22px]",
  text3xl: "tw-text-[30px] tw-font-normal md:tw-text-[25px] sm:tw-text-[22px]",  // adjusted the stylings
  text4xl: "tw-text-[40px] tw-font-normal md:tw-text-[38px] sm:tw-text-[36px]",
};

const ModalText = ({ children, className = "", as, size = "text4xl", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`tw-text-primary_maroon tw-font-playfairdisplay ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { ModalText };
