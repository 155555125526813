import { Button, Title, Text, Img, NotifySection } from "../../Component";
import React, { useState,useEffect,useCallback} from "react";
import { MESSAGE_TYPES } from "../../Constants/myDharmaContent";
import Snackbar from "../../Component/SnackBar";
export default function JoinTheWaitingListSection() {
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false); //initally set modal false
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: MESSAGE_TYPES.SUCCESS,
  });

  const showSnackbar = (message, type = MESSAGE_TYPES.SUCCESS) => {
    setSnackbar({
      open: true,
      message,
      type,
      key: Date.now(), // Ensure unique key to trigger useEffect
    });
  };
    const handleCloseSnackbar = useCallback(() => {
      setSnackbar(prev => ({ ...prev, open: false }));
    }, []);   //function to handle the closing of snackbar

    useEffect(() => {
      if (snackbar.open) {
        const timer = setTimeout(() => {
          setSnackbar(prev => ({ ...prev, open: false }));
        }, 10000); // Close Snackbar after 10 seconds
  
        return () => clearTimeout(timer); // Cleanup timeout on unmount or snackbar change
      }
    }, [snackbar]);

  const handleButtonClick = () => {
    setIsNotifyModalOpen(true); //set the modal to true to open notify section
  };
  return (
    <>
      {/* Join the waiting list section */}
      <div className="tw-flex tw-justify-center sm:tw-mt-[70px]">
        <div className="container tw-flex tw-items-center tw-justify-center tw-gap-[38px] md:tw-flex-col md:tw-px-5">  {/*alignment changes */}
          <Img
            src="images/img_image_49.png"
            alt="Promoimage"
            className="tw-h-[184px] tw-w-[30%] tw-object-contain md:tw-w-full sm:tw-hidden"
          />
          <div className="tw-flex tw-flex-1 tw-flex-col tw-items-start tw-gap-7 md:tw-self-stretch md:tw-items-center">
            <div className="tw-flex tw-flex-col tw-items-start tw-justify-center tw-gap-1 tw-self-stretch md:tw-text-center">
              <Text
                size="textxl"
                as="p"
                className="tw-text-[36px] tw-font-normal tw-text-primary_maroon md:tw-text-[34px] sm:tw-text-[30px] sm:tw-text-center"
              >
                Join the My Dharma waiting list
              </Text>
              <Img
                src="images/img_image_49.png"
                alt="Promoimage"
                className="tw-hidden tw-h-[184px] tw-w-[30%] tw-object-contain md:tw-w-full sm:tw-block"
              />
              <Title
                size="texts"
                as="h2"
                className="tw-text-[14px] tw-font-medium tw-tracking-[0.14px] tw-text-gray-900 sm:tw-text-center"
              >
                We will let you know as soon as it&apos;s available for download
                and use
              </Title>
            </div>
            <Button
              size="md"
              variant="fill"
              shape="square"
              className="md:tw-w-[100%] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5"
              onClick={handleButtonClick} //changed the function to handle the button click
            >
              Join the waiting list
            </Button>
          </div>
        </div>
      </div>
      <NotifySection
        isOpen={isNotifyModalOpen}
        onClose={() => setIsNotifyModalOpen(false)}
        showSnackbar={showSnackbar}
      />
       <Snackbar className="sm:tw-mt-70px" 
        key={snackbar.key}
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        onClose={handleCloseSnackbar}
      />
      {/*added the NotifySection and snackbar component */}
    </>
  );
}
