import React, { useEffect } from "react";
import styled from "styled-components";
// Theme constants
const COLORS = {
  SUCCESS: "#5a1740",
  WARNING: "#FFA500",
  ERROR: "#DC143C",
  DEFAULT: "#5a1740",
};

const BREAKPOINTS = {
  mobile: "480px",
  tablet: "768px",
};

// Utility function for status colors
const getStatusColor = (type) => {
  switch (type) {
    case "success":
      return COLORS.SUCCESS;
    case "warning":
      return COLORS.WARNING;
    case "error":
      return COLORS.ERROR;
    default:
      return COLORS.DEFAULT;
  }
};

// Utility function for icons
const getIconContent = (type) => {
  switch (type) {
    case "success":
      return '"✓"';
    case "warning":
      return '"!"';
    case "error":
      return '"×"';
    default:
      return '"✓"';
  }
};

// Styled components
const SnackbarContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ type }) => getStatusColor(type)};
  color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) =>
    visible ? "translate(-50%, 0)" : "translate(-50%, -20px)"};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  font-family: "Playfair Display", sans-serif;
  font-size: 14px;
  min-width: 486px;
  display: flex;
  align-items: center;
  gap: 12px;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    min-width: 90vw;
    max-width: 90vw;
    padding: 10px 20px;
    font-size: 12px;
    top: 10px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    min-width: 80vw;
    max-width: 80vw;
    padding: 8px 16px;
    font-size: 11px;
    top: 5px;
  }
`;

const NotificationIcon = styled.div`
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &::before {
    content: ${({ type }) => getIconContent(type)};
    color: ${({ type }) => getStatusColor(type)};
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: ${BREAKPOINTS.tablet}) {
    width: 16px;
    height: 16px;
    &::before {
      font-size: 12px;
    }
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    width: 14px;
    height: 14px;
    &::before {
      font-size: 10px;
    }
  }
`;

const MessageContainer = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 0 12px;

  @media (max-width: ${BREAKPOINTS.tablet}) {
    font-size: 12px;
    padding: 0 10px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 11px;
    padding: 0 8px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: ${BREAKPOINTS.tablet}) {
    font-size: 12px;
    padding: 3px;
  }

  @media (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 11px;
    padding: 2px;
  }
`;

// Main component
const Snackbar = ({ message, onClose, type = "success", open }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, 10000); // Close after 10 seconds

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);//changed the snack bar time

  if (!open) return null;   

  return (
    <SnackbarContainer visible={!!message} type={type}>
      <NotificationIcon type={type} />
      <MessageContainer>{message}</MessageContainer>
      <CloseButton onClick={onClose} aria-label="Close notification">
        ✕
      </CloseButton>
    </SnackbarContainer>
  );
};

export default Snackbar;
