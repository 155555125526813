import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Component/Header";
import DharmaAppFeaturesSection from "./DharmaAppFeaturesSection";
import DharmaOfferingsSection from "./DharmaOfferingsSection";
import DharmaSection from "./DharmaSection";
import DharmaSection1 from "./DharmaSection1";
import JoinTheWaitingListSection from "./JoinTheWaitingListSection";
import Footer from "../../Component/Footer/Footer";
import Whatsapp from "../../Component/Whatsapp/Whatsapp";
import NotifySection from "../../Component/NotifySection";
import Snackbar from "../../Component/SnackBar";
import { MESSAGE_TYPES } from "../../Constants/myDharmaContent";
import RegisterButton from "../../Component/RegisterButton";

export default function DharmaPage() {
  const [isNotifyModalOpen, setIsNotifyModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    type: MESSAGE_TYPES.SUCCESS,
  });

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);   //function to handle the closing of snackbar

  const showSnackbar = (message, type = MESSAGE_TYPES.SUCCESS) => {
    setSnackbar({
      open: true,
      message,
      type,
      key: Date.now(), // Ensure unique key to trigger useEffect
    });
  };

  useEffect(() => {
    if (snackbar.open) {
      const timer = setTimeout(() => {
        setSnackbar(prev => ({ ...prev, open: false }));
      }, 10000); // Close Snackbar after 10 seconds

      return () => clearTimeout(timer); // Cleanup timeout on unmount or snackbar change
    }
  }, [snackbar]);

  return (
    <>
      <Helmet>
        <title>About Dharma - Discover the Essence of Sanatana Practices</title>
        <meta
          name="description"
          content="Explore the My Dharma App and learn the significance of daily puja, Sanatana Dharma's view of time, and how to practice and understand Dharma. Join our waiting list for exclusive access."
        />
      </Helmet>
      <div className="tw-w-full">
        <div className="tw-flex tw-h-[750px] tw-flex-col tw-gap-2.5 tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto">
          <Header className="tw-mt-2.5 tw-gap-5" />
          <DharmaSection />
        </div>

        <div className="tw-flex tw-flex-col">    {/*adjusted the alignment for small screens*/}
          {/* dharma offerings section */}
          <div className="sm:tw-order-2">
            <DharmaOfferingsSection />
          </div>

          {/* dharma app features section */}
          <div className="sm:tw-order-1">   {/*adjusted the alignment for small screens*/}
            <DharmaAppFeaturesSection />
          </div>
        </div>

        {/* dharma section */}
        <DharmaSection1 />
        <div className="tw-h-[344px] tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat tw-py-20 md:tw-h-auto md:tw-py-5">
          {/* join the waiting list section */}
          <JoinTheWaitingListSection />
        </div>

        <Footer />
        <Whatsapp />
        <RegisterButton setIsNotifyModalOpen={setIsNotifyModalOpen} />
        <NotifySection
          isOpen={isNotifyModalOpen}
          onClose={() => setIsNotifyModalOpen(false)}
          showSnackbar={showSnackbar}
        />
        <Snackbar className="sm:tw-mt-70px" 
        key={snackbar.key}
        open={snackbar.open}
        message={snackbar.message}
        type={snackbar.type}
        onClose={handleCloseSnackbar}
      />
      </div>
    </>
  );
}
