import { Button, Title, Img, Text } from "../../Component";
import PujaDetail from "../../Component/PujaDetail";
import React, { useState, useEffect } from "react";
import { PujaService } from "../../Services/PujaService";
import { useNavigate } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const PujaDetailList = ({ pujas,tab }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(0);
  }, [tab]);

  if (pujas.length <= 3) {
    return (
      <div
      className={`${
        pujas.length < 3 ? "tw-flex sm:tw-overflow-x-auto sm:tw-scroll-snap-x sm:tw-scroll-snap-mandatory" : "tw-grid"
      } tw-grid-cols-3 md:tw-grid-cols-2 sm:tw-grid-cols-1 tw-gap-4`}
    >
      {pujas?.map((puja, index) => (
        <div
          key={`pujaDetail-${index}`}
          className={`tw-px-2 ${
            pujas.length < 3 ? "sm:tw-min-w-full sm:tw-scroll-snap-align-start" : ""
          }`}
        >
          <PujaDetail 
            pujas={puja} 
            className="tw-h-[460px] tw-w-[400px]"
          />
        </div>
      ))}
    </div>    
    );
  }  //display cards without carousel in case of more than 3 cards

  const responsive = {
    0: { items: 1 },     // mobile first
    375: { items: 1 },   // smaller phones
    640: { items: 2 },   // sm breakpoint
    1024: { items: 3 }   // lg breakpoint
  };

  const items = pujas.map((puja, index) => (
    <div className="tw-px-2" key={`pujaDetail-${index}`}>
      <PujaDetail pujas={puja} className="tw-h-[460px]"/>
    </div>
  ));

  const getItemsPerSlide = () => {
    const width = window.innerWidth;
    if (width >= 1024) return 3;
    if (width >= 640) return 2;
    return 1;
  };  //adjusted the card sizes

  const isFirstSlide = activeIndex === 0;
  const isLastSlide = activeIndex === pujas.length - getItemsPerSlide();

  const handleSlideChanged = (e) => {
    setActiveIndex(e.item);
  };    //added the responsive carousel

  return (
    <AliceCarousel
      items={items}
      responsive={responsive}
      autoPlay
      autoPlayInterval={3000}
      mouseTracking
      disableDotsControls
      controlsStrategy="alternate"
      keyboardNavigation
      onSlideChanged={handleSlideChanged}
      activeIndex={activeIndex}
      renderPrevButton={() => (
        <button 
          disabled={isFirstSlide}
          className={`tw-absolute tw-left-[-50px] sm:tw-left-[-40px] tw-top-1/2 tw-transform tw-translate-y-[-50%] 
            tw-bg-none tw-border-none tw-p-2 sm:tw-pr-0
            ${isFirstSlide ? 'tw-opacity-50 tw-cursor-not-allowed' : 'tw-shadow-md tw-cursor-pointer'}`}
        >
          <img 
            src="/assets/images/image-slide-left.png" 
            alt="Previous"
            className={isFirstSlide ? 'tw-opacity-50' : ''} 
          />
        </button>
      )}
      renderNextButton={() => (
        <button 
          disabled={isLastSlide}
          className={`tw-absolute tw-right-[-55px] sm:tw-right-[-40px] tw-top-1/2 tw-transform tw-translate-y-[-50%] 
            tw-bg-none tw-border-none tw-p-2 sm:tw-pl-0
            ${isLastSlide ? 'tw-opacity-50 tw-cursor-not-allowed' : 'tw-shadow-md tw-cursor-pointer'}`}
        >
          <img 
            src="/assets/images/image-slide-right.png" 
            alt="Next"
            className={isLastSlide ? 'tw-opacity-50' : ''} 
          />
        </button>
      )}
    />      //added the carousel with customized arrows
  );
};

export default function PujaOfferingsSection() {
  const [occEventList, setOccEventList] = useState([]);
  const [lifeEventList, setLifeEventList] = useState([]);
  const [active, setActive] = useState("Life Event Based");
  const [pujas, setPujas] = useState([]);
  const navigate = useNavigate();

  const handleClick = (index) => {
    setActive(index); // Update state on click
  };

  useEffect(() => {
    const fetchPujas = async () => {
      try {
        const response = await PujaService.getAllPujas();
        const pujaListData = response.data.data[1].subCategories;
        const lifeEventData = response.data.data[0].subCategories[0].pujasubcategorymappings;
        setLifeEventList(lifeEventData);
        const selectedOcc = pujaListData.find((i) => i.name === active);
        if (selectedOcc) {
          setOccEventList(selectedOcc.pujasubcategorymappings);
        }
      } catch (err) {
        console.log("Error fetching pujas:", err);
      }
    };
    fetchPujas();
  }, [active]);

  useEffect(() => {
    if (active === "Life Event Based") {
      setPujas(lifeEventList);
    } else {
      setPujas(occEventList);
    }
  }, [active, lifeEventList, occEventList]);

  const getImageSrc = (tab) => {
    switch (tab) {
      case "Life Event Based":
        return "images/life_based_events_tab.svg";
      case "Homam":
        return "images/homam_tab.svg";
      case "Well Being":
        return "images/well_being.svg";
      case "Vratam":
        return "images/vratam.svg";
      default:
        return "";
    }
  };

  return (
    <>
      <div>
        <div className="container tw-flex tw-items-center tw-justify-center tw-bg-cover tw-bg-no-repeat md:tw-h-auto md:tw-py-5">
          <div className="container tw-flex tw-justify-center md:tw-px-5">{/*adjusted the alignment */}
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-10">
              <div className="tw-flex tw-flex-col tw-gap-10 tw-self-stretch">
                <div className="tw-flex tw-flex-col tw-items-center">
                  <Text as="p" className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-center sm:tw-text-[34px] sm:tw-mt-[20px]"> {/* adjusted the text size */}
                    Our Puja Offerings
                  </Text>
                  <div className="tw-flex tw-items-start tw-justify-center tw-self-stretch md:tw-flex-col">
                    <div className="tw-flex tw-flex-1 tw-justify-between tw-mt-[20px] md:tw-self-stretch sm:tw-flex-row">
                      {["Life Event Based", "Homam", "Well Being", "Vratam"].map((tab) => (
                        <div
                          key={tab}
                          className="tw-flex tw-flex-col tw-items-center tw-gap-1 tw-align tw-cursor-pointer"
                          style={{ borderBottom: active === tab ? "4px solid #5a1740" : "" }}
                          onClick={() => handleClick(tab)}
                        >
                          <Img
                            src={getImageSrc(tab)}
                            alt={`${tab} Icon`}
                            className="tw-h-[42px] tw-w-[42px] sm:tw-hidden"
                          />
                          <Title size="texts" as="h3" className="tw-mb-3 tw-font-quicksand tw-text-[14px] tw-font-medium tw-text-gray-900">
                            {tab}
                          </Title>
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="tw-w-full tw-color-[#262525] tw-mt-[0px]" />
                </div>
                <div className="container tw-flex tw-gap-10">
                  <PujaDetailList pujas={pujas} tab={active} />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-gap-[30px]">
                <Title size="textlg" as="h6" className="tw-self-end tw-text-[24px] tw-font-medium tw-text-gray-900 md:tw-text-[22px] sm:tw-text-[14px] sm:tw-text-center">
                  Would like to see more pujas?
                </Title>
                <Button
                  variant="fill"
                  shape="square"
                  className="tw-min-w-[122px] tw-px-2 tw-font-quicksand tw-font-medium tw-tracking-[0.16px]"
                  onClick={() => {
                    localStorage.setItem("tab", active);
                    navigate("/offerings");
                  }}
                >
                  View all pujas
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
