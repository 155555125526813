import { Button, Text } from "../../Component";
import UserProfile from "../../Component/UserProfile";
import React, { Suspense } from "react";
import { CUSTOMER_REVIEWS_LIST } from "../../Constants/myDharmaContent";

export default function CustomerReviewsSection() {
  return (
    <>
      {/* customer reviews section */}
      <div>
        <div className="tw-flex tw-items-center tw-justify-center tw-bg-[url(/public/images/background_image1.png)] tw-bg-cover tw-bg-no-repeat md:tw-h-auto md:tw-py-5">
          <div className="container tw-flex tw-justify-center md:tw-px-5">
            {/*changed the styling according to alignment */}
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-10">
              <Text
                as="p"
                className="tw-text-[70px] tw-font-normal tw-text-primary_maroon md:tw-text-[48px] sm:tw-text-center sm:tw-text-[34px]"
              >
                {" "}
                {/* adjusted the text size */}
                Karishye reviews
              </Text>
              <div className="tw-flex tw-gap-10 tw-self-stretch md:tw-flex-col sm:tw-hidden">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {CUSTOMER_REVIEWS_LIST.map((d, index) => (
                    <UserProfile {...d} key={"reviewList" + index} />
                  ))}
                </Suspense>
              </div>
              <div className="tw-hidden tw-flex tw-gap-10 tw-self-stretch md:tw-flex-col sm:tw-block">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {CUSTOMER_REVIEWS_LIST.slice(0, 1).map((d, index) => (
                    <UserProfile {...d} key={"reviewList" + index} />
                  ))}
                </Suspense>
              </div>
              <Button
                size="md"
                variant="fill"
                shape="square"
                className="tw-min-w-[252px] tw-px-[34px] tw-font-quicksand tw-font-medium tw-tracking-[0.16px] sm:tw-px-5"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_GOOGLE_REVIEWS_URL,
                    "_blank"
                  )
                } //google reviews url from env
              >
                See more reviews
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
