import React, { useEffect } from "react"; //added the detail modal component
import DetailsPage from "../../Page/Details/index.jsx";

const DetailsModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    //dunction to prevent scrolling when modal is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="modal fade signup-form show tw-fixed tw-inset-0 tw-z-[1000] tw-overflow-hidden tw-flex tw-items-center tw-justify-center"
      role="dialog"
      style={{ display: isOpen ? "flex" : "none" }}
    >
      {/* Overlay */}
      <div
        className="tw-fixed tw-inset-0 tw-bg-black tw-opacity-100 tw-pointer-events-auto"
        onClick={onClose}
      />

      {/* Modal Content */}
      <div className="modal-dialog modal-dialog-centered tw-relative tw-w-[90%] tw-max-w-[1440px] tw-h-[90vh] tw-m-auto tw-z-[1001]">
        <div className="modal-content tw-h-full tw-w-full tw-overflow-y-auto tw-bg-[#ffffff] tw-rounded-lg tw-relative">
          <div className="tw-sticky tw-top-0 tw-right-0 tw-flex tw-justify-end tw-p-4 tw-bg-white">
            <button
              type="button"
              className="tw-absolute tw-right-4 tw-top-4 tw-z-10 tw-border-none tw-bg-[#ffffff] tw-opacity-100 tw-shadow-lg hover:tw-shadow-xl tw-transition-all tw-duration-300 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center sm:tw-w-8 sm:tw-h-8"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >{/*close the modal when clicked on close button */}
              <img src="images/close.png" />
            </button>
          </div>  {/* Close button styles changed*/}
          <DetailsPage isModal={true} />
        </div>
      </div>
    </div>
  );
};

export default DetailsModal;